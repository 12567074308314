/* eslint-disable nordic/i18n-jsx-no-literals */

const React = require('react');
const { useI18n } = require('nordic/i18n');

const config = require('../../../../../config/default');


const FooterView = ({ location, siteId, user, refererURL, userLogged }) => {
  const { i18n } = useI18n();
  const text = config.defaultText[location];
  const siteConfig = config.fendConfig[siteId];
  let userInfo;
  const date = new Date();

  if (userLogged) {
    userInfo = (
      <div className="user-name with-padding">
        {i18n.gettext('Ingresaste como')} <span> {user.nickname} </span>
      </div>
    );
  } else {
    userInfo = (
      <div className="user-name">
        <ul>
          <li>
            <a href={`${siteConfig.url.registration}?confirmation_url=${refererURL}`}>{i18n.gettext('Regístrate')}</a>
          </li>
          <li>
            <a href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=ml&go=${refererURL}`}>
              {i18n.gettext('Ingresar')}
            </a>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <footer className="nav-footer">
      <div className="nav-footer-wrapper">
        {userInfo}
        <div className="nav-footer-copyright">
          <p className="nav-footer-copyright__text">
            Copyright © {date.getFullYear()} {text.commons.companyName}
          </p>
        </div>
        <div className="nav-footer-navigation">
          <ul className="nav-footer-navigation__list">
            <li className="nav-footer-navigation__items">
              <a href={siteConfig.footer.partners.termsAndConditions}>{i18n.gettext('Términos y condiciones')}</a>
            </li>
            <li className="nav-footer-navigation__items">
              <a href={siteConfig.footer.url.privacy}>{i18n.gettext('Cómo cuidamos tu privacidad')}</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

module.exports = FooterView;
