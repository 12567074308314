const sanitizeHtml = require('sanitize-html');

const sanitizeHTML = html => sanitizeHtml(html, {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'svg', 'g', 'circle', 'path', 'style', 'input', 'iframe']),
  allowProtocolRelative: false,
  allowVulnerableTags: true,
  allowedAttributes: {
    '*': ['id', 'data-src', 'class', 'style'],
    img: ['src', 'alt', 'loading', 'height', 'width'],
    input: [
      {
        name: 'type',
        values: ['checkbox', 'radio'],
      },
      'checked', 'name', 'disabled',
    ],
    td: ['colspan'],
    th: ['colspan'],
    iframe: ['*'],
    circle: ['*'],
    path: ['*'],
    svg: ['*'],
    a: ['*'],
    g: ['*'],
  },
});

export {
  sanitizeHTML,
};
