const React = require('react');
const moment = require('moment');
const Page = require('nordic/page');
const { injectI18n } = require('nordic/i18n');
const Script = require('nordic/script');
const Footer = require('./FooterView');
const HeadView = require('../../../components/HeadView');
const HeaderView = require('./HeaderView');
const { sanitizeHTML } = require('../../../helpers/sanitizeHtml');

class PartnersView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = ({
    });
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.assignDropdown = this.assignDropdown.bind(this);
  }

  componentDidMount() {
    const items = document.getElementsByClassName('solution__list-item');
    const stages = document.getElementsByClassName('solution__list-stage');
    for (let index = 0; index < items.length; index += 1) {
      const item = items[index];

      if (typeof (item) !== 'undefined' && item != null) {
        item.addEventListener('click', () => {
          this.selectItem(items, index);
          this.showStage(stages, index);
        });
      }
    }
    this.assignDropdown(false);

    let counter = 1;

    if (!document.getElementById(`radio${counter}`)) return;

    setInterval(() => {
      document.getElementById(`radio${counter}`).checked = true;
      // eslint-disable-next-line no-plusplus
      counter++;
      if (counter > 3) {
        counter = 1;
      }
    }, 5000);

    window.addEventListener('load', setInterval);
  }

  openDropdown(e) {
    document.querySelector('.solution__list').classList.add('dropdown__open');
    e.currentTarget.removeEventListener('click', this.openDropdown);
    window.addEventListener('click', this.closeDropdown, true);
  }

  closeDropdown() {
    document.querySelector('.solution__list').classList.remove('dropdown__open');
    window.removeEventListener('click', this.closeDropdown, true);
    window.addEventListener('click', this.assignDropdown);
  }

  assignDropdown(isWindow) {
    const $dropdown = document.querySelector('.solution__list');
    if (typeof ($dropdown) !== 'undefined' && $dropdown != null) {
      $dropdown.addEventListener('click', this.openDropdown);
      if (isWindow) {
        window.removeEventListener('click', this.assignDropdown);
      }
    }
  }

  selectItem(items, selectedItemIndex) {
    for (let index = 0; index < items.length; index += 1) {
      const stage = items[index];
      stage.className = index === selectedItemIndex ? 'solution__list-item active' : 'solution__list-item';
    }
  }

  showStage(stages, selectedStageIndex) {
    for (let index = 0; index < stages.length; index += 1) {
      const stage = stages[index];
      stage.className = index === selectedStageIndex ? 'solution__list-stage active' : 'solution__list-stage';
    }
  }

  render() {
    const {
      device,
      translations,
      locale,
      headerParams,
      siteId,
      user,
      refererURL,
      page,
      categories,
      location,
      texts,
      userLogged,
      i18n,
    } = this.props;
    const state = {
      device,
      translations,
      locale,
      headerParams,
      siteId,
      user,
      refererURL,
      page,
      categories,
      location,
      texts,
      userLogged,
    };

    moment.locale(location);

    return (
      <Page name="partners" key="partners" id="partners" state={state}>
        <HeadView i18n={i18n} location={location} title={page.title} description={page.excerpt} />
        <HeaderView
          {...headerParams}
          user={user}
          refererURL={refererURL}
          siteId={siteId}
          categories={categories}
          location={location}
          texts={texts}
          userLogged={userLogged}
          i18n={i18n}
        />

        <div
          className="inner-content"
          dangerouslySetInnerHTML={{ __html: sanitizeHTML(page.content) }}
        />
        <Footer i18n={i18n} user={user} refererURL={refererURL} siteId={siteId} location={location} userLogged={userLogged} />

        <Script src="vendor.js" />
      </Page>
    );
  }
}

module.exports = injectI18n(PartnersView);
